import React from 'react';
import { TextField, MenuItem, Select, FormControl, InputLabel, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#892222',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#EFE9E9',
            top: '-14px',
            left: '-11px',
          },
        },
      },
    },
  },
});

const ContactForm = () => {
  const [formValues, setFormValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    service: '',
    query: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log(formValues);
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit} className="space-y-8 px-7">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <FormControl variant="outlined" fullWidth>
              <TextField
                name="firstName"
                label="First Name"
                variant="outlined"
                className='bg-white rounded-[4px]'
                value={formValues.firstName}
                onChange={handleChange}
                fullWidth
              />
            </FormControl>
          </div>
          <div>
            <FormControl variant="outlined"  fullWidth>
              <TextField
                name="lastName"
                label="Last Name"
                variant="outlined"
                className='bg-white rounded-[4px]'
                value={formValues.lastName}
                onChange={handleChange}
                fullWidth
              />
            </FormControl>
          </div>
        </div>
        <div>
          <FormControl variant="outlined" fullWidth>
            <TextField
              name="email"
              label="Email Address"
              type="email"
              variant="outlined"
              className='bg-white rounded-[4px]'
              value={formValues.email}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Country Code</InputLabel>
              <Select
                name="countryCode"
                className='bg-white rounded-[4px]'
                value={formValues.countryCode}
                onChange={handleChange}
                label="Country Code"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="+1">+1</MenuItem>
                <MenuItem value="+44">+44</MenuItem>
                <MenuItem value="+91">+91</MenuItem>
                {/* Add more country codes as needed */}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3">
            <FormControl variant="outlined" fullWidth>
              <TextField
                name="phoneNumber"
                label="Phone Number"
                type="number"
                variant="outlined"
                className='bg-white rounded-[4px]'
                value={formValues.phoneNumber}
                onChange={handleChange}
                fullWidth
              />
            </FormControl>
          </div>
        </div>
        <div>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Select Service</InputLabel>
            <Select
              name="service"
              className='bg-white rounded-[4px]'
              value={formValues.service}
              onChange={handleChange}
              label="Select Service"
              fullWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="service1">Service 1</MenuItem>
              <MenuItem value="service2">Service 2</MenuItem>
              <MenuItem value="service3">Service 3</MenuItem>
              {/* Add more services as needed */}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="outlined" fullWidth>
            <TextField
              name="query"
              label="Query"
              variant="outlined"
              multiline
              rows={4}
              className='bg-white rounded-[4px]'
              value={formValues.query}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </div>
        <div className='flex justify-end'>
          <Button
            variant="outlined"
            type="submit"
            fullWidth
            className="mt-4 !bg-red !text-[white] !text-[18px] !font-semibold !capitalize !rounded-[4px] !border-none !w-max"
          >
            Submit
          </Button>
        </div>
      </form>
    </ThemeProvider>
  );
};

export default ContactForm;