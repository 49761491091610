import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CallIcon from "@mui/icons-material/Call";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Grid,
  useTheme,
  useMediaQuery,
  Button
} from "@mui/material";

const HeaderComponent = ({contacts}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const toggleDrawer = useCallback((open) => () => {
    setIsOpen(open);
  }, []);

  const isActiveLink = (path) => location.pathname === path;

  return (
    <div>
      <div className="bg-red flex justify-end">
        <IconButton className="!text-white !flex !items-center gap-x-2 !pr-11">
          <CallIcon className="w-5 h-5" />
          <a href={`tel:${contacts.data[0].attributes.phone}`} className="text-[14px]">{contacts.data[0].attributes.phone}</a>
        </IconButton>
      </div>
      <AppBar position="static">
        <Toolbar className="bg-d-black !min-h-0">
          {isMdUp ? (
            <Grid container className="flex justify-between items-center">
              <Grid item>
                <div className="w-20 h-20">
                  <img src='/logo-dark.jpg' alt="marisree technologies" className="w-full h-full"/>
                </div>
              </Grid>
              <Grid item>
                <List component="nav" className="flex !py-0">
                  {['/', '/services', '/portfolio', '/contact'].map((path, index) => (
                    <ListItem key={index} className="!py-0">
                      <Button
                        href={path}
                        className={`!text-white ${isActiveLink(path) ? '!text-l-grey !w-max !min-w-[unset] relative after:content-[""] after:w-full after:h-[3px] after:bg-l-grey after:absolute after:bottom-0' : ''}`}
                      >
                        <span className="text-[18px]">
                          {path === '/' ? 'Home' : path.charAt(1).toUpperCase() + path.slice(2)}
                        </span>
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          ) : (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <div className="w-20 h-20">
                <img src='/logo-dark.jpg' alt="marisree technologies" className="w-full h-full"/>
              </div>
              <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
                <div
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <List>
                    {['Home', 'Services', 'Portfolio', 'Contact'].map((text, index) => (
                      <ListItemButton
                        key={text}
                        component="a"
                        href={text === 'Home' ? '/' : `/${text.toLowerCase()}`}
                        onClick={toggleDrawer(false)}
                      >
                        <ListItemText primary={text} />
                      </ListItemButton>
                    ))}
                  </List>
                </div>
              </Drawer>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderComponent;
