import React from "react";
import HeaderComponent from "../../components/Header/Header";
import FooterComponent from "../../components/Footer/Footer";
import ContactForm from "../../components/ContactForm/ContactForm";
import MailIcon from "../../assets/images/icons/mail-r.svg";
import PhoneIcon from "../../assets/images/icons/phone-r.svg";

const ContactPage = ({contacts = { data: [] }}) => {
    return (
        <div className="bg-black min-h-screen">
            <HeaderComponent contacts={contacts} />
            <div className="my-12 max-sm:px-3">
                <h3 className="text-[32px] text-white text-center font-semibold uppercase mb-3 max-sm:text-[28px]">get in touch</h3>
            </div>
            <div className="grid grid-cols-2 my-6 max-md:grid-cols-1 max-md:gap-5">
                <div>
                    <div className="flex flex-col gap-y-3 px-10">
                        <div className="bg-white flex items-center justify-center w-40 h-40 rounded-full">
                            <img src={MailIcon} alt="mail" />
                        </div>
                        <ul className="flex flex-col gap-y-3">
                            <li className="text-white text-[32px] font-medium">
                                {contacts.data[0].attributes.email}
                            </li>
                            <li className="text-white flex items-center gap-3 text-[32px] font-medium">
                                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-white p-2">
                                    <img src={PhoneIcon} alt="call"/>
                                </div>
                                <span>{contacts.data[0].attributes.phone}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <ContactForm />
            </div>
            <FooterComponent />
        </div>
    )
}

export default ContactPage