import React from "react";
import HeaderComponent from "../../components/Header/Header";
import FooterComponent from "../../components/Footer/Footer";
import BannerSlider from "../../components/BannerSlider/BannerSlider";
import ReachStrip from "../../components/ReachStrip/ReachStrip";
import TestimonialComponent from "../../components/Testimonial/Testimonial";

const HomePage = ({ banners = [], abouts = { data: [] }, aboutImage = { data: [] }, testimonials = { data: [] }, contacts = { data: [] } }) => {
    
    return (
        <div className="bg-black min-h-screen">
            <HeaderComponent contacts={contacts} />
            <BannerSlider banners={banners} />
            <div>
                <h3 className="text-[32px] text-white text-center font-semibold uppercase max-sm:text-[28px]">How We Work</h3>
                <div className="grid grid-cols-2 max-md:grid-cols-1">
                    <ul className="pl-14 pr-32 max-lg:pr-12 max-lg:pl-7 max-md:order-2 max-sm:pl-4 max-sm:pr-4">
                        {abouts.data.map((about, index) => (
                            <li className="py-4" key={index}>
                                <h4 className="text-[28px] text-white-two capitalize pb-7">{about.attributes.Heading}</h4>
                                <p className="text-white">
                                    {about.attributes.Content}
                                </p>
                            </li>
                        ))}
                    </ul>
                    <div className="px-5">
                        <img
                            src={`https://server.marisree.com${aboutImage.data[0].attributes.image.data.attributes.url}`}
                            alt="image-side"
                            className="max-md:w-3/4 max-md:mx-auto max-md:mt-5"
                        />
                    </div>
                </div>
                <ReachStrip />
                <TestimonialComponent testimonials={testimonials}/>
            </div>
            <FooterComponent />
        </div>
    );
};

export default HomePage;
