import React from "react";
import { Card, CardContent, Avatar } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialComponent = ({testimonials}) => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        centerPadding: "20px",
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "10px"
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "0px"
                }
            }
        ]
    };

    return (
        <div className="px-5">
            <h3 className="text-2xl text-white text-center font-semibold uppercase mb-3 md:text-3xl">happy clients</h3>
            <Slider {...settings} className="my-5">
                {testimonials.data.map((testimonial, index) => (
                    <div className="px-2" key={index}>
                        <Card className="!bg-d-black px-9 max-md:px-3 rounded-md !overflow-visible mx-2">
                            <CardContent className="relative flex flex-col items-center">
                                <Avatar className="!absolute !top-[-15px]">
                                    <img 
                                        src={`https://server.marisree.com${testimonial.attributes.avatar.data.attributes.url}`} 
                                        alt={testimonial.attributes.avatar.data.attributes.alternativeText} 
                                    />
                                </Avatar>
                                <div className="mt-6 text-center">
                                    <h5 className="text-lg text-white font-semibold capitalize mb-3">{testimonial.attributes.name}</h5>
                                    <p className="text-base text-white">{testimonial.attributes.comment}</p>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default TestimonialComponent;
