import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Home/Home';
import ServicesPage from './pages/Services/Services';
import PortfolioPage from './pages/Portfolio/Portfolio';
import ContactPage from './pages/Contact/Contact';
import useFetch from './hooks/useFetch';

function App() {

  const { loading: loadingBanners, data: banners, error: errorBanners } = useFetch('https://server.marisree.com/api/banners?populate=*');
  const { loading: loadingAbout, data: abouts, error: errorAbout } = useFetch('https://server.marisree.com/api/abouts?populate=*');
  const { loading: loadingAboutImage, data: aboutImage, error: errorAboutImage } = useFetch('https://server.marisree.com/api/about-images?populate=*');
  const { loading: loadingTestimonial, data: testimonials, error: errorTestimonial } = useFetch('https://server.marisree.com/api/testimonials?populate=*');
  const { loading: loadingServiceDetails, data: serviceDetails, error: errorServiceDetails } = useFetch('https://server.marisree.com/api/service-details?populate=*');
  const { loading: loadingServices, data: services, error: errorServices } = useFetch('https://server.marisree.com/api/services?populate=*');
  const { loading: loadingPortfolioDetails, data: portfolioDetails, error: errorPortfolioDetails } = useFetch('https://server.marisree.com/api/portfolio-details?populate=*');
  const { loading: loadingPortfolios, data: portfolios, error: errorPortfolios } = useFetch('https://server.marisree.com/api/portfolios?populate=*');
  const { loading: loadingContacts, data: contacts, error: errorContacts } = useFetch('https://server.marisree.com/api/contact-details?populate=*');

  if (loadingBanners || loadingAbout || loadingAboutImage || loadingTestimonial || loadingServices || loadingServiceDetails || loadingPortfolios || loadingContacts) return <p>Loading...</p>;
  if (errorBanners || errorAbout || errorAboutImage || errorTestimonial || errorServices || errorServiceDetails || errorPortfolios || errorContacts) return <p>Error loading data</p>;
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<HomePage banners={banners || []} abouts={abouts || []} aboutImage= {aboutImage || []} testimonials= {testimonials || []} contacts={contacts || []} />} />
          <Route path="/services" element={<ServicesPage serviceDetails={serviceDetails || []} services={services || []} contacts={contacts || []} />} />
          <Route path="/portfolio" element={<PortfolioPage portfolios={portfolios || []} portfolioDetails={portfolioDetails || []} contacts={contacts || []} />} />
          <Route path="/contact" element={<ContactPage contacts={contacts || []} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
