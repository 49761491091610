import React from "react";
import HeaderComponent from "../../components/Header/Header";
import FooterComponent from "../../components/Footer/Footer";
import ReachStrip from "../../components/ReachStrip/ReachStrip";
import linkIcon from "../../assets/images/icons/link-w.svg";
import { Link } from "react-router-dom";

const PortfolioPage = ({portfolios = { data:[] }, portfolioDetails = { data:[] }, contacts = { data: [] } }) => {
    return (
        <div className="bg-black min-h-screen">
            <HeaderComponent contacts={contacts} />
            <div className="w-[60%] mx-auto my-12 max-md:w-4/5 max-sm:w-full max-sm:px-3">
                <h3 className="text-[32px] text-white text-center font-semibold uppercase mb-3 max-sm:text-[28px]">{portfolioDetails.data[0].attributes.heading}</h3>
                <p className="text-white text-center">
                {portfolioDetails.data[0].attributes.content}
                </p>
            </div>
            <div className="grid grid-cols-1 gap-x-14 gap-y-12 my-12 w-4/5 mx-auto max-lg:w-full max-lg:px-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {portfolios.data.map((portfolio, index) => (
                    <div className="bg-white-two rounded-[4px] h-[300px] relative overflow-hidden cursor-pointer portfolio-item" key={index}>
                        <img src={`https://server.marisree.com${portfolio.attributes.image.data.attributes.url}`} alt={portfolio.attributes.image.data.attributes.alternativeText} className="w-100 h-full object-cover"/>
                        <div className="flex items-center justify-center bg-overlay h-full w-full absolute overlay">
                            <Link to={portfolio.attributes.link} className="w-12 h-12 rounded-full bg-red flex items-center justify-center">
                                <img src={linkIcon} alt="link"/>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            <ReachStrip />
            <FooterComponent />
        </div>
    )
}

export default PortfolioPage