import React from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const BannerSlider = ({banners}) => {

   const latestBanner = banners.data.reduce((prev, current) => (prev.id > current.id) ? prev : current);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true, // Enable auto-scrolling
        autoplaySpeed: 3000, // Set auto-scrolling speed in milliseconds

        appendDots: dots => (
            <div>
                <ul className="m-0 p-0"> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div className="w-5 h-5 rounded-full flex items-center justify-center bg-l-grey slick-custom-dot">
            </div>
        )
    };

    return (
        <div className="w-full overflow-hidden">
            <Slider {...settings}>
                {latestBanner.attributes.image.data.map((image) =>
                    <div key={image.id} className="h-[380px]"> {/* h-64 is for height of 250px */}
                        <img 
                            src={`https://server.marisree.com${image.attributes.url}`} 
                            alt={image.attributes.alternativeText} 
                            className="w-full h-full object-cover" 
                        />
                    </div>
                )}
            </Slider>
        </div>
    );
}

export default BannerSlider;
