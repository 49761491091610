import React from "react";

const FooterComponent = () => {
    return (
        <div className="bg-red flex justify-center py-3">
            <p className="text-[14px] text-white">copyright @ marisree.com</p>
        </div>
    )
}

export default FooterComponent