import React from "react";
import HeaderComponent from "../../components/Header/Header";
import FooterComponent from "../../components/Footer/Footer";
import ReachStrip from "../../components/ReachStrip/ReachStrip";

const ServicesPage = ({services = { data: [] }, serviceDetails={ data:[] }
, contacts = { data: [] } }) => {
   
    return (
        <div className="bg-black min-h-screen">
            <HeaderComponent contacts={contacts} />
            <div className="w-[60%] mx-auto my-12 max-md:w-4/5 max-sm:w-full max-sm:px-3">
                <h3 className="text-[32px] text-white text-center font-semibold uppercase mb-3 max-sm:text-[28px]">{serviceDetails.data[0].attributes.heading}</h3>
                <p className="text-white text-center">
                 {serviceDetails.data[0].attributes.content}
                </p>
            </div>
            <div className="grid grid-cols-3 my-12 w-4/5 mx-auto max-lg:w-full max-lg:px-3 max-md:grid-cols-1">
                {services.data.map((service, index) => (
                    <div className="max-md:my-4" key={index}>
                        <div className="h-96 p-6 max-sm:h-auto">
                            <img src={`https://server.marisree.com${service.attributes.image.data.attributes.url}`} alt={service.attributes.image.data.attributes.alternativeText} className="w-full object-contain h-full"/>
                        </div>
                        <h5 className="text-[22px] text-white font-semibold capitalize text-center">{service.attributes.title}</h5>
                    </div>
                ))}
                {/* <div className="max-md:my-4">
                    <div className="h-96 p-6 max-sm:h-auto">
                        <img src={WebsiteDev} alt="web" className="w-full object-contain h-full" />
                    </div>
                    <h5 className="text-[22px] text-white font-semibold capitalize text-center">website development</h5>
                </div>
                <div className="max-md:my-4">
                    <div className="h-96 p-6 max-sm:h-auto">
                        <img src={TransServices} alt="transition" className="w-full object-contain h-full" />
                    </div>
                    <h5 className="text-[22px] text-white font-semibold capitalize text-center">translation services</h5>
                </div> */}
            </div>
            <ReachStrip />
            <FooterComponent />
        </div>
    )
}

export default ServicesPage