import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ReachStrip = () => {
    return (
        <div className="bg-d-black py-7 px-40 flex items-center justify-between flex-wrap mb-10 max-md:px-20 max-sm:px-3">
            <h1 className="text-white text-[32px] font-semibold uppercase max-sm:text-[24px]">need help ?</h1>
            <Link to='/contact'>
                <Button variant="outlined" className="!bg-red !text-[white] !text-[18px] !font-semibold !capitalize !rounded-[4px] !border-none">
                    Reach us
                </Button>
            </Link>
        </div>
    )
}

export default ReachStrip